import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(private translate: TranslateService
  ) { }

  /**
   * @method: setLanguage
   * @param: languageCode:string - default - 'en'
   * 
   */
  setLanguage(languageCode) {
    this.translate.use(languageCode);
  }

  getDefaultLanguage() {
    const language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    return language;
  }
  setDefaultLanguage() {
    this.translate.setDefaultLang('en');
    return;
  }

  getTranslation(key, variable?: object) {
    // let str;
    // this.translate.get(key).subscribe((res: string) => {
    //   console.log(res);
    //   str = res;
    //   return res;
    // });
    if (variable)
      return this.translate.instant(key, variable);
    else
      return this.translate.instant(key);
  }
}
