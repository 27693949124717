import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/auth';

import { LIST_DEFAULT_LIMIT } from '../constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private snapshotChangesSubscription: any;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth
  ) { }

  /**
   * @method : getDocumentData
   * @param : documentPath 
   * @returns : Object | {data: Object, ref: docRef}
   */
  getDocumentData = async (documentPath) => {
    const docRef = await this.afs.doc(documentPath).ref.get();
    // console.log('docRef', docRef);
    if (!docRef.exists) {
      return null;
    }
    return { data: docRef.data(), ref: docRef };
    // this.afs.collection('people').doc(currentUser.uid)
  }

  getDocumentDataRef = async (documentPath) => {
    // console.log('documentPath', documentPath)
    const docRef = await this.afs.doc(documentPath).ref
    // console.log('docRef', docRef);
    // docRef.onSnapshot
    return docRef;
  }

  addToCollection = async (collectionName, data) => {
    // console.log(collectionName, data)
    if (data && data.hasOwnProperty('id')) {

      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.collection(collectionName).doc(data.id).set({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
      return documentRef;
    } else if (data && data.hasOwnProperty('parent_id') && !data.hasOwnProperty('id')) {
      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.collection(collectionName).doc(data.parent_id).collection(collectionName).add({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() });
      return documentRef;
    } else if (data && data.hasOwnProperty('parent_id') && data.hasOwnProperty('id')) {
      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.collection(collectionName).doc(data.parent_id).collection(collectionName).doc(data.id).set({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
      return documentRef;
    } else {
      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.collection(collectionName).add({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() });
      return documentRef;
    }
  }

  addToSubCollection = async (collectionName, documentId, subCollectionName, data) => {
    if (data && data.hasOwnProperty('id')) {
      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.doc(`${collectionName}/${documentId}/${subCollectionName}/${data.id}`).set({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });

      return documentRef;
    }
    else {
      // tslint:disable-next-line: max-line-length
      const documentRef = await this.afs.doc(`${collectionName}/${documentId}`).collection(subCollectionName).add({ ...data, timestamp: firebase.firestore.FieldValue.serverTimestamp() });
      return documentRef;
    }

  }

  /**
   * @param : collectionName
   * @param: data :{ orderBy?: string, startAt = null, endAt = null, 
   * whereCondition {whereField: null, whereOp: null, whereOpVal: null}[],
   *  limit }
   * '<' | '<=' | '==' | '>' | '>=' | 'array-contains' | 'array-contains-any' | 'in' - where ops
   */
  getCollectionList = async (collectionName, data) => {
    // console.log(collectionName, data)
    if (collectionName) {

      let query = this.afs.firestore.collection(collectionName);

      if (data.whereCondition && data.whereCondition.length > 0) {
        data.whereCondition.forEach((c) => {
          query.where(c.whereField, c.whereOp, c.whereOpVal);
        });
      }
      if (data.orderBy) {
        query.orderBy(data.orderBy.field, data.orderBy.direction ? data.orderBy.direction : 'asc');
      }
      if (data.startAt) {
        query.startAt(data.startAt);
      }
      if (data.startAfter) {
        query.startAfter(data.startAfter);
      }
      if (data.endAt) {
        query.endAt(data.endAt)
      }
      if (data.endBefore) {
        query.endBefore(data.endBefore)
      }
      if (data.limit) {
        query.limit(data.limit)
      } else {
        // if (!data.noLimit) {
        query.limit(LIST_DEFAULT_LIMIT);
        // }
      }
      // console.log(query);
      const querySnapShot = await query.get();

      const results = [];
      const masterRecords = {};
      if (querySnapShot) {
        querySnapShot.forEach(doc => {
          results.push(doc.id);
          masterRecords[doc.id] = { snapshot: doc, data: doc.data() };
        });
      }
      return { list: results, listById: masterRecords };
    } else {
      return {};
    }
  }

  deleteField = async (collectionName, documentId, fieldName) => {
    const deleteInstance = firebase.firestore.FieldValue.delete();
    await this.afs.collection(collectionName).doc(`${documentId}`).update({
      [fieldName]: deleteInstance
    });
  }

  /**
   * createSnapshotChangesSubscription
   * @param : collectionName
   */
  createSnapshotChangesSubscription(collectionName, handler) {
    // console.log('here for snapshot subscription')
    const collection = this.afs.collection(collectionName);
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    const subscription = collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        if (a.payload.doc.exists) {
          const data = a.payload.doc.data() as { type: string, senderId: string, receiverId: string };
          const id = a.payload.doc.id;
          return { id, ...data };
        }
      }))
    ).subscribe((changes) => {
      // console.log(changes)
      handler(changes)
    });
    return subscription;
  }

  deleteDocument(documentPath) {
    this.afs.doc(documentPath).delete();
  }

  getFirestoreServerTimestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

}
