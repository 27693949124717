import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})

export class StatusService {
    constructor(private http: HttpClient){

    }

    getData(email){
        return this.http.get(
            "https://www.directoapp.com/send/getTotalLeads.php?email_address="+email
        );
    }
}