import { Component, Input, OnInit } from '@angular/core';
import { firestore } from "firebase";
import { AngularFirestore } from "@angular/fire/firestore";
import { UserConnectionService } from "../../services/user.Connection.Service";
import { firebaseConstants } from "../../constants";
import * as firebase from 'firebase/app';
import { AgoraCallService } from '../../services/agora-call.service';

import { FirebaseService } from "../../services/firebase.service";
import { AlertController } from '@ionic/angular';
import { FirebaseFunctionsService } from 'src/app/services/firebase.functions.service';

const { dealers, chatMessages, users, chats } = firebaseConstants.firestoreCollections;

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
})
export class ChatViewComponent implements OnInit {

  chatID = '';
  minimize = false;
  otherUserId;
  otherUserDetails;
  otherUserData;
  thisUserData;
  close = false;
  msg = '';
  @Input() userDetails: any;

  // @Input() chatID: any;
  // public messageDetails: string;
  messageList = [];

  adminUser = firebase.auth().currentUser;


  // chatID = '';
  // adminID = '';

  constructor(private firebaseService: FirebaseService, private afs: AngularFirestore, 
    private alertController:AlertController,
    private agoraService: AgoraCallService,
    private firebaseFunctionsService: FirebaseFunctionsService,
    private chatConSrv: UserConnectionService) {

  }

  ngOnInit() {
    this.messageList = [];
    // console.log('adminUser =========>', this.adminUser)
    // console.log('userDetails =========>', this.userDetails);

    if (this.userDetails.hasOwnProperty('message')) {
      if (this.adminUser.uid === this.userDetails.from) {
        this.otherUserId = this.userDetails.to;
      } else {
        this.otherUserId = this.userDetails.from;
      }
    } else {
      if (this.adminUser.uid === this.userDetails.senderId) {
        this.otherUserId = this.userDetails.receiverId;
      } else {
        this.otherUserId = this.userDetails.senderId;
      }
    }
    // console.log(this.otherUserId+"Likhilesh");
    this.chatID = this.adminUser.uid < this.otherUserId ? this.adminUser.uid + this.otherUserId : this.otherUserId + this.adminUser.uid;
    const usersCollection = this.afs.collection("chatMessages").doc(this.chatID).collection("chatMessages", ref => ref
      .orderBy('timestamp', 'asc')).snapshotChanges()
      .subscribe(response => {
        if (!response.length) {
          // console.log("No Data Available");
          return false;
        }
        this.messageList = [];
        for (let item of response) {
          this.messageList.push(item.payload.doc.data());
        }
      }, error => {
      });

      // this.firebaseService.addToCollection(`${dealers}/${senderData.uid}/${chats}`, recentChatdata);
      this.afs.collection(`${dealers}/${this.adminUser.uid}/${chats}`).doc(`${this.chatID}`).update({
        seen:true,
      }).then( ()=>{
        // console.log('!!!!!!! SEEN !!!!!!!!');
      }).catch(e=>{
        // console.log('Error in updating entry');
        });

        this.afs.collection(`${users}/`).doc(`${this.otherUserId}`).ref.get().then((res)=>{
          if(res.exists){
            this.otherUserData=res.data();
        }
        else{
          // console.log('Nothing!');
        }
          });

          this.afs.collection(`${dealers}/`).doc(`${this.adminUser.uid}`).ref.get().then((res)=>{
            if(res.exists){
              this.thisUserData=res.data();
          }
          else{
            // console.log('Nothing!');
          }
            });
  }

  sendNotificationToApp(){
            // const data={
            //   senderName: this.thisUserData.username,
            //   senderData: JSON.stringify({
            //     ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
            //   }),
            //   receiverData: JSON.stringify({
            //     ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
            //   })
            //   message:recentChatData.message,
            //   senderId: res.id,
            //   receiverId: this.otherUserData.id,
            //   receiverTokens: this.otherUserData.fcmTokens,
            //   isDealerChat: this.state.isDealerChat
            // }
  }

  updateList(event) {
    // console.log('************')
    if (event.code === "Enter" && this.msg.trim() !== '') {
      // console.log('#######')
      this.sendMessage();
    }
  }


  getOtherUserData() {
    if (this.userDetails.hasOwnProperty('message')) {
      if (this.adminUser.uid === this.userDetails.from) {
        this.otherUserId = this.userDetails.to;
        this.otherUserDetails = {
          name: this.userDetails.receiverName,
          id: this.userDetails.to
        }
      } else {
        this.otherUserId = this.userDetails.from;
        this.otherUserDetails = {
          name: this.userDetails.senderName,
          id: this.userDetails.from
        }
      }
    } else {
      if (this.adminUser.uid === this.userDetails.senderId) {
        this.otherUserDetails = {
          name: this.userDetails.receiverName,
          id: this.userDetails.receiverId
        }
        this.otherUserId = this.userDetails.receiverId;
      } else {
        this.otherUserId = this.userDetails.senderId;
        this.otherUserDetails = {
          name: this.userDetails.senderName,
          id: this.userDetails.senderId
        }
      }
    }
  }

  sendMessage() {
    // console.log('Sending message');
    // collections = dealers, admins, chatMessages
    //
    const senderData = this.adminUser;
    const receiverData = this.otherUserId;
    let rName=this.userDetails.senderName;
    if(this.userDetails.senderName===senderData.displayName){
      rName=this.userDetails.recceiverName;
    }

    const isDealerChat = null;

    //  console.log(receiverData+"receiverData Likhilesh this.chatID "+this.chatID+" this.userDetails.name "+this.userDetails.senderName);
    const chatData = {

      from: senderData.uid,
      to: receiverData,
      message: this.msg,
      senderRef: 'dealers/' + senderData.uid,
      receiverRef: 'users/' + receiverData,
      senderName: senderData.displayName,
      recceiverName: rName,
      timestamp: firestore.FieldValue.serverTimestamp(),
      createAt: Math.floor(new Date().getTime() / 100),
      isDealerChat: true,
    }

    const recentChatdata = {
      id: this.chatID,
      from: senderData.uid,
      to: receiverData,
      message: this.msg,
      parent_id: this.chatID,
      senderRef: 'dealers/' + senderData.uid,
      receiverRef: '/users/' + receiverData,
      senderName: senderData.displayName,
      recceiverName: rName,
      timestamp: firestore.FieldValue.serverTimestamp(),
      createAt: Math.floor(new Date().getTime() / 100),
      isDealerChat: true,
      seen: true,
    }
    const recentChatdata2 = {
      id: this.chatID,
      from: senderData.uid,
      to: receiverData,
      message: this.msg,
      parent_id: this.chatID,
      senderRef: 'dealers/' + senderData.uid,
      receiverRef: '/users/' + receiverData,
      senderName: senderData.displayName,
      recceiverName: rName,
      timestamp: firestore.FieldValue.serverTimestamp(),
      createAt: Math.floor(new Date().getTime() / 100),
      isDealerChat: true,
      seen: false,
    }
    //
    try {
      // this.firebaseService.addToCollection({chatMessages}, chatData)
      this.firebaseService.addToCollection(`${chatMessages}/${this.chatID}/${chatMessages}`, chatData)
    } catch (e) {
      // log error
    }

    // this.firebaseService.addToCollection({admins}/{senderData.uid}/{chats}, recentChatdata)
    this.firebaseService.addToCollection(`${dealers}/${senderData.uid}/${chats}`, recentChatdata);

    // this.firebaseService.addToCollection(dealers[receiverData.id}]/{chats}, recentChatdata);
    this.firebaseService.addToCollection(`${users}/${receiverData}/${chats}`, recentChatdata2);
    //
    // console.log("This USer Data"+JSON.stringify(this.thisUserData));

    const data={
      senderName: this.thisUserData.username,
      senderData: JSON.stringify({
        ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
      }),
      receiverData: JSON.stringify({
        ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
      }),
      message:recentChatdata2.message,
      senderId: this.thisUserData.id,
      receiverId: this.otherUserData.id,
      receiverTokens: this.otherUserData.fcmTokens,
      isDealerChat: true,
    }
    // console.log("This USer Data"+JSON.stringify(this.thisUserData));

    this.firebaseFunctionsService.callHttpFunction('sendNotificationOnNewMessage', data).then((data:any) => {

    });


    this.msg = '';


  }

  changeMessage(e) {
    // console.log('************', e)
    this.msg += e.key;
  }

// *************************
  minimizeChatWindow() {
    this.minimize = true;
  }

  resizeChatWindow() {
    this.minimize = false;
  }

  closeChatWindow() {
    this.close = true;
    this.chatConSrv.closeUser(this.userDetails);
  }

  
}
