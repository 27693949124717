import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { firebaseConstants } from "../../../constants";
import { AuthService } from "../../../services/auth.service";
import { FirebaseService } from "../../../services/firebase.service";
const { chats, dealers, calls } = firebaseConstants.firestoreCollections;


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit {

  logUser = firebase.auth().currentUser;

  userName;
  userNameValue=true;
  userNameColor="dark";
  companyName;
  companyNameValue=true;
  companyNameColor="dark";
  phoneNumber:'';
  phoneNumberValue=true;
  phoneNumberColor="dark";
  email;
  emailValue=true;
  emailColor="dark";
  border=1.5;
  border2=0;
  edit=false;
 

  constructor(
    private modalController:ModalController,
    private afs: AngularFirestore,
    private alertController:AlertController,
    private authService: AuthService) { }

  ngOnInit() {
    // this.afs.collection(`${dealers}/${this.authService.userData.id}`).ref.get().then( (res)=>{  
    //   console.log('!!\n'+res);
    // }).catch(e=>{
    //   console.log('Error in updating entry');
    //   });

    this.afs.collection(`${dealers}/`).doc(`${this.authService.userData.id}`).ref.get().then((res)=>{
      if(res.exists){
        // console.log(res.data());
        this.companyName=res.data().CompanyName;
        this.phoneNumber=res.data().address.zip;
        this.email=res.data().email;
        this.userName=res.data().username;
      }
      else{
        // console.log('Nothing!');
      }
    }).catch(e=>{
      // console.log('Error in updating entry');
      });
    }

  
        
 
  dismiss(){
    this.modalController.dismiss();
    // console.log(this.logUser);
  }

  editAll(){
    this.editUserName();
    this.editCompanyName();
    this.editPhoneNumber();
    this.edit=!this.edit;
  }
  editUserName(){
    this.userNameValue=!this.userNameValue;
    // console.log("Edit UserName Called!");
    // console.log(this.userName);
    if(this.userNameValue){
      this.userNameColor="dark";
    }
    else {
      this.userNameColor="primary";
    }
  }

  editCompanyName(){
    this.companyNameValue=!this.companyNameValue;
    // console.log("Edit companyName Called!");
    // console.log(this.companyName);
    if(this.companyNameValue){
      this.companyNameColor="dark";
    }
    else {
      this.companyNameColor="primary";
    }
  }

  editEmail(){
    this.emailValue=!this.emailValue;
    // console.log("Edit email Called!");
    // console.log(this.email);
    if(this.emailValue){
      this.emailColor="dark";
    }
    else {
      this.emailColor="primary";
    }
  }

  editPhoneNumber(){
    this.phoneNumberValue=!this.phoneNumberValue;
    // console.log("Edit PhoneNumber Called!");
    // console.log(this.phoneNumber);
    if(this.phoneNumberValue){
      this.phoneNumberColor="dark";
    }
    else {
      this.phoneNumberColor="primary";
    }
  }

  update(){
    this.afs.collection(`${dealers}/`).doc(`${this.authService.userData.id}`).update({
        CompanyName:this.companyName,
        address:{zip:this.phoneNumber},
        username:this.userName,
    }).catch(e=>{
      // console.log('Error in updating entry');
      });
    this.editAll();
    this.showAlert();
  }


  showAlert() {
    var audio = new Audio('../assets/aud.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 3000);

    this.alertController.create({
      header: ' ',
      subHeader: 'Your profile has been updated',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
        }
      },]
    }).then(res => {
      res.present();
    });
  }
  
}
