import { CallsPageModule } from './../pages/calls/calls.module';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { LayoutComponent } from '../components/common/layout/layout.component';
import {CallsViewComponent} from "./calls-view/calls-view.component";
import {ChatViewComponent} from "./chat-view/chat-view.component";
import {ApoinmentAlertComponent} from "./apoinment-alert/apoinment-alert.component";
import {FormsModule} from "@angular/forms";
import { ProfcompComponent } from '../pages/menu/profcomp/profcomp.component';
import { CallsPageRoutingModule } from '../pages/calls/calls-routing.module';

@NgModule({
  declarations: [ProfcompComponent, LayoutComponent, CallsViewComponent, ChatViewComponent, ApoinmentAlertComponent],
  exports: [ProfcompComponent, LayoutComponent, CallsViewComponent, ChatViewComponent, ApoinmentAlertComponent],
  imports: [
    IonicModule,
    CommonModule, FormsModule
  ],

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppComponentsModule { }
