import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { firebaseConstants, LIST_DEFAULT_LIMIT } from '../../../constants';
import { FirebaseService } from "../../../services/firebase.service";
const { contactdirectoweb } = firebaseConstants.firestoreCollections;
import { firestore } from "firebase";


@Component({
  selector: 'app-contact-directo',
  templateUrl: './contact-directo.page.html',
  styleUrls: ['./contact-directo.page.scss'],
})
export class ContactDirectoPage implements OnInit {

  logUser = firebase.auth().currentUser;
  email='contact@directoapp.com';

  subject:'';
  message:'';
  constructor(
    private firebaseService: FirebaseService,
    private modalController:ModalController,
    private alertController:AlertController) { }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  showAlert() {

    const data={
      subject:this.subject,
      message:this.message,
      resolved:false,
      timestamp:firestore.FieldValue.serverTimestamp(),
    }

    this.firebaseService.addToCollection(`${contactdirectoweb}`, data);

    this.alertController.create({
      header: ' ',
      subHeader: ' ',
      message: 'Your message has been sent',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
          this.dismiss();
        }
      },]
    }).then(res => {
      res.present();
    });
  }

}
