import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { firebaseConstants } from '../constants/';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData;
  private userDataSubscription;
  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth
  ) { }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
        .then(
          res => resolve(res),
          err => reject(err));
    });
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut()
        .then(() => {
          if (this.userDataSubscription) {
            this.userDataSubscription();
          }
          resolve(true);
        }).catch((error) => {
          // console.log(error);
          reject();
        });
    });
  }

  /**
   * @method : getLoggedInUserData
   * @param : uid : string | user's uid
   * @returns : userData : Object
   */
  getLoggedInUserData = async (uid) => {
    // console.log('46', uid);
    if (!uid) {
      return null;
    }
    const { dealers } = firebaseConstants.firestoreCollections;
    const userData = await this.firebaseService.getDocumentData(`${dealers}/${uid}`);
    this.userData = userData.data;
    if (userData.ref) {
      this.userDataSubscription = userData.ref.ref.onSnapshot(
        (documentSnapshot) => {
          this.userData = documentSnapshot.data();
        }
      )
    }
    // console.log(this.userData)
    return userData;
  }

  /**
   * @method : saveNotificationToken
   * @param : token : string
   * @returns : void
   */
  saveNotificationToken = async (token) => {

    const { dealers } = firebaseConstants.firestoreCollections;
    // tslint:disable-next-line: max-line-length
    await this.firebaseService.addToCollection(dealers, { id: this.userData.id, fcmTokens: firebase.firestore.FieldValue.arrayUnion(token) });
  }

}