import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserConnectionService {

    public userChatOpen = new Subject();
    public closeChat = new Subject();
    public logout = new Subject();
    public readonly chatOpen: Observable<any> = this.userChatOpen.asObservable();
    public readonly chatClose: Observable<any> = this.closeChat.asObservable();
    public readonly logoutUser: Observable<any> = this.logout.asObservable();
    constructor() {
    }

    openChatView(user: any) {
        this.userChatOpen.next(user);
    }

    closeUser(name: any) {
        this.closeChat.next(name);
    }

    logoutCurrentUser() {
        this.logout.next(new Date().getTime());
    }


}
