import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { StatusService } from 'src/app/services/status.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {
  // userLimit=-1;
  // status;
  userCount=-1;
  // gotData;
  @Input() status;
  @Input() userLimit;
  logUser = firebase.auth().currentUser;

  constructor(
    private modalController:ModalController,
    private sS:StatusService) { }

  ngOnInit() {
    this.getStatus();
  }


  getStatus(){
    // console.log("\n User Limit: "+ this.userLimit+"\n Status: "+this.status);
    // console.log(this.logUser.email);
    // this.sS.getData(this.logUser.email).subscribe(data => {
    //   console.log("Got the details:\n"+data);
    //   this.gotData=data;
    // });

    // setTimeout(()=>{
    //   this.status=this.gotData.status;
    //   this.userLimit=this.gotData.total_leads;
    //   console.log("status:\n"+this.status);

    // },2000)
  }

  dismiss(){
    this.modalController.dismiss();
    // console.log('!');
  }

}
