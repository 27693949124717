import { Injectable } from '@angular/core';
// import { NgxAgoraService, StreamEvent, AgoraClient, ClientEvent } from 'ngx-agora';
import { Router } from '@angular/router';

import { FirebaseService } from './firebase.service';
import { CommonService } from './common.service';
import { TranslationService } from './translation.service';
import { CALL_OUT_TIMEOUT, firebaseConstants } from '../constants';

const { dealers, currentCall, calls } = firebaseConstants.firestoreCollections;

@Injectable({
  providedIn: 'root'
})

export class AgoraCallService {
  public isCallActive = false;
  public isSubscribedForCalls = false;
  public localStream;
  public currentCallRoom;
  public isAlertShown = false;
  public alertRef;
  public currentCallData: any;
  public outGoingCallData: any
  // tslint:disable-next-line: max-line-length

  constructor(private firebaseService: FirebaseService, private commonService: CommonService, private translationService: TranslationService, private router: Router) {
  }

  // createClient = () => {
  //   this.agoraService.createClient({ mode: 'rtc', codec: 'vp8' });
  // }

  /**
   * createCallSubscription : to create subscription to database for new calls
   * @param userId 
   */
  createCallWatch(userId, joinCallback?: any) {
    // console.log('here for creating call watch')
    // tslint:disable-next-line: max-line-length
    // if (!this.isSubscribedForCalls) {

    this.firebaseService.createSnapshotChangesSubscription(`${dealers}/${userId}/${currentCall}`, (data) => {

      const currTimeInSeconds = this.commonService.currentTimeInSeconds();
      data.forEach(callData => {
        // console.warn(callData)
        // if (callData && callData.receiverId == userId) {
        if (callData) {
          if (!this.currentCallRoom && this.isAlertShown === false) {
            const secondsElapsed =(Date.now() - callData.callInitiatedTimestamp)/1000
            // console.warn('callData, secondsElapsed', callData, secondsElapsed)
            if (callData.cancelled === true || callData.missed === true || callData.disconnected || callData.rejected || secondsElapsed > 35) {
              // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++')
              if (this.currentCallRoom == callData.roomName && this.alertRef) {
                this.alertRef.dismiss();
              }
              this.saveToMissedCallsAndRemoveFromCurrentCalls(callData, callData.receiverId == userId?callData.receiverId:callData.senderId);
            } else {
              if (callData && callData.receiverId == userId) {
                this.showIncomingCallAlert(callData);
              }
            }

          }
        }
        // }
      });
      // }
      // console.log('inside collection snapshot changes callbacl');
    });
    // }
  }

  deletefromCurrentCallAndSaveToCalls(callData) {

    this.firebaseService.addToSubCollection(dealers, callData.receiverId, calls, callData);
    this.firebaseService.deleteDocument(`${dealers}/${callData.receiverId}/${currentCall}/${callData.id}`)
  }

  onAcceptCall = (data) => {
    this.firebaseService.addToSubCollection(dealers, data.receiverId, currentCall, { ...data, acceptedByReceiver: true });
  }

  onRejectCall = (data) => {
    this.alertRef = undefined;
    this.firebaseService.addToSubCollection(dealers, data.receiverId, currentCall, { ...data, rejected: true });
  }

  onCallDisconnected = (data) => {
    this.alertRef = undefined;
    this.currentCallRoom = null;
    this.isAlertShown = false;
    this.isCallActive = false;
    this.firebaseService.addToSubCollection(dealers, data.receiverId, currentCall, { ...data, disconnected: true });
  }

  cancelCall = (data) => {
    this.alertRef = undefined;
    this.currentCallRoom = null;
    this.isAlertShown = false;
    this.isCallActive = false;
    this.firebaseService.addToSubCollection(dealers, data.receiverId, currentCall, { ...data, cancelled: true });
  }

  showIncomingCallAlert = (callData) => {

    var audio = new Audio('../assets/ring.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 30000);

    this.currentCallRoom = callData.roomName;
    // tslint:disable-next-line: max-line-length
    const acceptCallback = (data) => {
      
      // console.warn('new call data', data);
      this.isCallActive = true;
      this.currentCallData = data

      this.router.navigate(['/call'])
      audio.pause();
      this.onAcceptCall(data);
    };

    const rejectCallback = (data) => {
      this.isCallActive = false;
      // console.log('call rejected', data);
      audio.pause();
      this.onRejectCall(data);
    };

    const buttons = [
      {
        text: this.translationService.getTranslation('ACCEPT'),
        cssClass: 'primary',
        handler: (blah) => {
          // console.log('Confirm Cancel: blah');

          acceptCallback(callData);
        }
      },
      {
        text: this.translationService.getTranslation('REJECT'),
        cssClass: 'secondary',
        handler: (blah) => {
          // console.log('Confirm Cancel: blah');
          rejectCallback(callData);
        }
      }
    ];
    // tslint:disable-next-line: max-line-length
    this.alertRef = this.commonService.showAlert(this.translationService.getTranslation('INCOMING_CALL'), this.translationService.getTranslation('INCOMING_CALL_FROM', { name: callData.senderName }), buttons);
    this.isAlertShown = true;

  }

  saveToMissedCallsAndRemoveFromCurrentCalls(callData, userCollectionId) {
    if (callData && userCollectionId) {
      const { dealers, currentCall, calls } = firebaseConstants.firestoreCollections;
      this.firebaseService.addToSubCollection(dealers, userCollectionId, calls, { ...callData, missed: true });
      this.firebaseService.deleteDocument(`${dealers}/${userCollectionId}/${currentCall}/${callData.id}`)  
    }
    

    //   saveToMissedCallsAndRemoveFromCurrentCalls(callData) {
    //     // const { dealers, currentCall, calls } = firebaseConstants.firestoreCollections;
    //     // this.firebaseService.addToSubCollection(dealers, callData.receiverId, calls, { ...callData, missed: true });
    //     // this.firebaseService.deleteDocument(`${dealers}/${callData.receiverId}/${currentCall}/${callData.id}`)
    // }
  }
}
