import { ContactDirectoPageModule } from './pages/menu/contact-directo/contact-directo.module';
import { PasswordChangePageModule } from './pages/menu/password-change/password-change.module';
import { StatusPageModule } from './pages/menu/status/status.module';
import { ScheduleAppointmentPageModule } from './pages/menu/schedule-appointment/schedule-appointment.module';
import { AppoinmentDetailsPageModule } from './pages/menu/appoinment-details/appoinment-details.module';
import { ProfilePageModule } from './pages/menu/profile/profile.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { DatePipe } from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, REGION, ORIGIN } from '@angular/fire/functions';

import { NgxAgoraModule, AgoraConfig } from 'ngx-agora';

import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CallComponent } from './components/call-component/call-component.component';
import {CallsViewComponent} from "./components/calls-view/calls-view.component";
import {AppComponentsModule} from "./components/app-components.module";
import {ApoinmentAlertComponent} from "./components/apoinment-alert/apoinment-alert.component";
// import {ProfcompComponent} from "./pages/menu/profcomp.component";
import { ProfcompComponent } from './pages/menu/profcomp/profcomp.component';
import { ProfilePage } from './pages/menu/profile/profile.page';
import { UserProfilePageModule } from './pages/menu/user-profile/user-profile.module';
import { PasswordChangePage } from './pages/menu/password-change/password-change.page';
import { ContactDirectoPage } from './pages/menu/contact-directo/contact-directo.page';
// import { LayoutComponent } from './components/common/layout/layout.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const agoraConfig: AgoraConfig = {
  AppID: environment.agoraAppId,
};

@NgModule({
  declarations: [AppComponent, CallComponent],
  entryComponents: [CallsViewComponent, ProfcompComponent,  ApoinmentAlertComponent],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    StatusPageModule,
    ProfilePageModule,
    UserProfilePageModule,
    AppoinmentDetailsPageModule,
    PasswordChangePageModule,
    ScheduleAppointmentPageModule,
    ContactDirectoPageModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase), // imports firebase/app
    AngularFirestoreModule, // imports firebase/firestore
    AngularFireAuthModule, // imports firebase/auth
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), // imports firebase/storage
    NgxAgoraModule.forRoot(agoraConfig),
    AngularFireFunctionsModule,
    AppComponentsModule,
  ],
  providers: [
    AngularFireAuth,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: REGION, useValue: 'asia-northeast1' }
    // {
    //   provide: REGION, useValue: { provide: ORIGIN, useValue: 'http://localhost:8100' }
    // }

  ],
  bootstrap: [AppComponent],

})
export class AppModule { }
