import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private toast: ToastController, private alert: AlertController) { }

  /**
   * @method : showToast
   * @param : title? : string
   * @param : message : string
   */
  showToast = async (message: string, duration?: number) => {
    const toast = await this.toast.create({
      message,
      duration: duration || 3000,
      position: 'top'
    });
    toast.present();
  }
  /**
   * @method : showAlert
   * @param : title? : string
   * @param : message : string
   */
  showAlert = async (header: string, message: string, buttons?: any) => {
    const alert = await this.alert.create({
      header,
      message,
      buttons: buttons ? buttons : []
      //   [
      //   {
      //     text: 'Cancel',
      //     role: 'cancel',
      //     cssClass: 'secondary',
      //     handler: (blah) => {
      //       console.log('Confirm Cancel: blah');
      //     }
      //   }, {
      //     text: 'Okay',
      //     handler: () => {
      //       console.log('Confirm Okay');
      //     }
      //   }
      // ]
    });

    await alert.present();
    return alert;
  }

  currentTimeInSeconds() {
    return new Date().getTime() / 1000;
  }

}
