import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.page.html',
  styleUrls: ['./password-change.page.scss'],
})
export class PasswordChangePage implements OnInit {
  logUser = firebase.auth().currentUser;

  email=this.logUser.email;
  constructor(
    private modalController:ModalController,
    private alertController:AlertController) { }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
  }

  reset(){
    firebase.auth().sendPasswordResetEmail(this.email)
    .then(() => {
    // Password reset email sent!
    // ..
    })
    .catch((error) => {
    // console.log('Try Again!!');
    });

    this.showAlert();
  }

  showAlert() {
    this.alertController.create({
      header: 'Email Sent!',
      subHeader: ' ',
      message: 'The details regarding password reset are sent to your registered email address.',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
          this.dismiss();
        }
      },]
    }).then(res => {
      res.present();
    });
  }

}
