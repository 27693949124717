import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'calls',
    loadChildren: () => import('./pages/calls/calls.module').then(m => m.CallsPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule)
  },
  {
    path: 'call',
    loadChildren: () => import('./pages/call/call.module').then( m => m.CallPageModule)
  },
  {
    path: 'appoinment',
    loadChildren: () => import('./pages/appoinment/appoinment.module').then(m => m.AppoinmentPageModule)
  },
  {
    path: 'appoinment-old',
    loadChildren: () => import('./pages/appoinment-old/appoinment-old.module').then(m => m.AppoinmentOldPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/menu/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'password-change',
    loadChildren: () => import('./pages/menu/password-change/password-change.module').then( m => m.PasswordChangePageModule)
  },
  {
    path: 'contact-directo',
    loadChildren: () => import('./pages/menu/contact-directo/contact-directo.module').then( m => m.ContactDirectoPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/menu/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'schedule-appointment',
    loadChildren: () => import('./pages/menu/schedule-appointment/schedule-appointment.module').then( m => m.ScheduleAppointmentPageModule)
  },
  {
    path: 'appoinment-details',
    loadChildren: () => import('./pages/menu/appoinment-details/appoinment-details.module').then( m => m.AppoinmentDetailsPageModule)
  },
  {
    path: 'status',
    loadChildren: () => import('./pages/menu/status/status.module').then( m => m.StatusPageModule)
  },  {
    path: 'loading',
    loadChildren: () => import('./pages/menu/loading/loading.module').then( m => m.LoadingPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
