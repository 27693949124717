import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apoinment-alert',
  templateUrl: './apoinment-alert.component.html',
  styleUrls: ['./apoinment-alert.component.scss'],
})
export class ApoinmentAlertComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
