import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class FirebaseFunctionsService {


  constructor(
    public fns: AngularFireFunctions
  ) { }


  // call some firebase function
  callHttpFunction(funcName, funcData = {}) {
    return new Promise((resolve, reject) => {
      const callable = this.fns.httpsCallable(funcName);
      callable(funcData).toPromise().then((data) => {
        // console.log('data123', data)
        resolve(data);
      }).catch((error) => {
        // console.log(error);
        reject(null);
      });
    });

  }

}
