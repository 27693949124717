export const firebaseConstants = {
    firestoreCollections: {
        users: 'users',
        friendShips: 'friendships',
        notifications: 'notifications',
        calls: 'calls',
        currentCall: 'currentCall', //user's subcollection
        chatMessages: 'chatMessages',
        chats: 'chats',
        dealers: 'dealers',
        appointments: 'appointments',
        admins: 'admins',
        contactdirectoweb: 'contactdirectoweb',
    }
};

export const notificationTypes = {
    friendRequest: 'friendRequest',
    newAudioCall: 'newAudioCall',
    newVideoCall: 'newVideoCall',
    friendRequestAccepted: 'friendRequestAccepted',
    newChatMessage: 'newChatMessage'
}

export const androidNotificationHandlers = {
    answer: 'answer',
    reject: 'reject'
}