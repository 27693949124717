import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  logUser = firebase.auth().currentUser;

  prefix=3;
  data=7;
  border=1;

  constructor(private modalController:ModalController) { }

  ngOnInit() {
  }

  dismiss(){
    this.modalController.dismiss();
    // console.log(this.logUser);
  }


}
