import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { CommonService } from './services/common.service';
import { TranslationService } from './services/translation.service';
import { AgoraCallService } from './services/agora-call.service'
import {UserConnectionService} from "./services/user.Connection.Service";

// import { NotificationsService } from './services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public subcription: any;
  public subcriptionLogout: any;
  public subcriptions: any;
  public selectedIndex = 0;
  public selectedSubIndex = 0;
  public userLoggedIn = false;
  public isCallActive = false;
  public users = [];
  appPages: { icon?: string, title: string, url: string, subMenus?: { icon?: string, title: string, url: string }[] }[];
  constructor(
    private platform: Platform,
    public afAuth: AngularFireAuth,
    public router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private translation: TranslationService,
    private agoraCallService: AgoraCallService,
    private chatCOnnection: UserConnectionService
    // private notificationsService: NotificationsService
  ) {
    this.initializeApp();
    this.appPages = [
      {
        title: 'DASHBOARD',
        url: '/home',
        // subMenus: [
        //   {
        //     title: 'INCOMING',
        //     url: '/calls'
        //   },
        //   {
        //     title: 'ANSWERED',
        //     url: '/calls'
        //   },
        //   {
        //     title: 'UNANSWERED',
        //     url: '/calls'
        //   }
        // ]
      },
      {
        title: 'CALLS',
        url: '/calls'
      },
      {
        title: 'CHATS',
        url: '/chats'
      },
      {
        title: 'Appointments',
        url: '/appoinment'
      },
      // *************************
      // {
      //   title: 'PAST_APPOINTMENTS',
      //   url: '/appoinment-old'
      // },
      // *************************
      // {
      //   title: 'CONTACTS',
      //   url: '/calls'
      // },
      // {
      //   title: 'SETTINGS',
      //   url: '/settings'
      // }
      // {
      //   title: this.translation.getTranslation('Calls'),
      //   url: '/calls'
      // },
      // {
      //   title: this.translation.getTranslation('Chats'),
      //   url: '/chats'
      // },
      // // {
      // //   title: this.translation.getTranslation('Chat'),
      // //   url: '/chat:id'
      // // }
    ];
    this.isCallActive = this.agoraCallService.isCallActive;
    this.handleChatView();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    // this.platform.ready().then(async () => {

    // });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.translation.setDefaultLanguage();
      this.translation.setLanguage('en');
      this.afAuth.user.subscribe(async (user) => {
        if (user) {
          console.log(user.uid);
          const userData = await this.authService.getLoggedInUserData(user.uid);
          console.log(userData);
          console.log(userData !== null);
          console.log(!userData.data.disabled);
          if (userData !== null && !userData.data.disabled) {

            this.userLoggedIn = true;
            this.router.navigate(['/home']);
            this.agoraCallService.createCallWatch(userData.data.id);
            // console.log(this.notificationsService.token)

            // await this.notificationsService.init();
            // await this.notificationsService.requestPermission();
            // if (this.notificationsService.token) {
            //   this.authService.saveNotificationToken(this.notificationsService.token)
            // }
            console.log('1222222')
          } else {
            console.log(userData);
            this.commonService.showToast('You are not allowed to login. You will be loggedout.');
            await this.authService.doLogout();
            this.router.navigate(['/login']);
          }

        } else {
          this.router.navigate(['/login']);
        }
      }, err => {
        this.router.navigate(['/login']);
      });

    });
  }

  ngOnInit() { }

  async logout() {
    await this.authService.doLogout();
    this.userLoggedIn = false;
    this.router.navigate(['/login']);
  }

  handleChatView() {
    this.subcription = this.chatCOnnection.chatOpen.subscribe((res) => {
      if (res) {
        // console.log('****************')
        let ind =  this.users.findIndex(item => item.id === res.id);
        if (ind === -1) {
          if (this.users.length > 3) {
            this.users.splice(0, 1);
          }
          this.users.push(res);
        }
      }
    });

    this.subcriptions = this.chatCOnnection.chatClose.subscribe((res) => {
      // console.log('&&&&&&&&&&&&&&&&&&')
      if (res) {
        this.removeSelectUser(res);
      }
    });

    this.subcriptionLogout = this.chatCOnnection.logoutUser.subscribe((res) => {
      // console.log('&&&&&&&&&&&&&&&&&&')
      if (res) {
        this.userLoggedIn = false;
      }
    });
  }

  removeSelectUser(user) {
    // console.log('############')
    let ind =  this.users.findIndex(item => item.id === user.id);
    if (ind > -1) {
      this.users.splice(ind, 1);
    }
  }
}
