import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {firebaseConstants} from "../../constants";
import {AuthService} from "../../services/auth.service";
import {AngularFirestore} from "@angular/fire/firestore";
import {FirebaseService} from "../../services/firebase.service";
import {FirebaseFunctionsService} from "../../services/firebase.functions.service";
import {Router} from "@angular/router";
import {AgoraCallService} from "../../services/agora-call.service";
import * as firebase from 'firebase/app';

const { calls, dealers } = firebaseConstants.firestoreCollections;

@Component({
  selector: 'app-calls-view',
  templateUrl: './calls-view.component.html',
  styleUrls: ['./calls-view.component.scss'],
})
export class CallsViewComponent implements OnInit {
  logUser = firebase.auth().currentUser;

  public call_status = null;
  public count = 0;

  userId;
  // Data object for listing items
  tableData: any[] = [];

  // Save first document in snapshot of items received
  firstInResponse: any = [];

  // Save last document in snapshot of items received
  lastInResponse: any = [];

  // Keep the array of first document of previous pages
  prev_strt_at: any = [];

  // Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  // Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;


  constructor(private navParams: NavParams, private modalCtrl: ModalController, private authService: AuthService, private afs: AngularFirestore, private firebaseService: FirebaseService, private firebaseFunctionsService: FirebaseFunctionsService, private router: Router, private agoraCallService: AgoraCallService) {
    // let data =this.navParams.get('data');
    // this.call_status = data.call_status;
    // this.count = data.count;
  }

  ngOnInit() {
    // console.log(`${this.call_status} ${this.count}`)
    this.loadItems();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  loadItems() {

    // const userId = this.authService.userData.uid

      if (this.call_status === 'unanswered') {
          this.afs.collection(`${dealers}/${this.authService.userData.id}/${calls}`, ref => ref
                  .where('receiverId', '==', this.logUser.uid)
                  .where('acceptedByReceiver', '==', false)
                  .limit(5)
              // .orderBy('timestamp', 'desc')
          ).snapshotChanges()
              .subscribe(response => {
                  if (!response.length) {
                      // console.log("No Data Available");
                      return false;
                  }
                  this.firstInResponse = response[0].payload.doc;
                  this.lastInResponse = response[response.length - 1].payload.doc;

                  this.tableData = [];
                  for (let item of response) {
                      // console.log('item.payload.doc.data()', item.payload.doc.data())
                      this.tableData.push(item.payload.doc.data());
                  }

                  //Initialize values
                  this.prev_strt_at = [];
                  this.pagination_clicked_count = 0;
                  this.disable_next = false;
                  this.disable_prev = false;

                  //Push first item to use for Previous action
                  this.push_prev_startAt(this.firstInResponse);
              }, error => {
              });
      }  else if (this.call_status === 'answerd') {
          this.afs.collection(`${dealers}/${this.authService.userData.id}/${calls}`, ref => ref
                  .where('receiverId', '==', this.logUser.uid)
                  .where('acceptedByReceiver', '==', true)
                  .limit(5)
              // .orderBy('timestamp', 'desc')
          ).snapshotChanges()
              .subscribe(response => {
                  if (!response.length) {
                      // console.log("No Data Available");
                      return false;
                  }
                  this.firstInResponse = response[0].payload.doc;
                  this.lastInResponse = response[response.length - 1].payload.doc;

                  this.tableData = [];
                  for (let item of response) {
                      // console.log('item.payload.doc.data()', item.payload.doc.data())
                      this.tableData.push(item.payload.doc.data());
                  }

                  //Initialize values
                  this.prev_strt_at = [];
                  this.pagination_clicked_count = 0;
                  this.disable_next = false;
                  this.disable_prev = false;

                  //Push first item to use for Previous action
                  this.push_prev_startAt(this.firstInResponse);
              }, error => {
              });
      } else {
          this.afs.collection(`${dealers}/${this.authService.userData.id}/${calls}`, ref => ref
                  .where('receiverId', '==', this.logUser.uid)
                  .limit(5)
              // .orderBy('timestamp', 'desc')
          ).snapshotChanges()
              .subscribe(response => {
                  if (!response.length) {
                      // console.log("No Data Available");
                      return false;
                  }
                  this.firstInResponse = response[0].payload.doc;
                  this.lastInResponse = response[response.length - 1].payload.doc;

                  this.tableData = [];
                  for (let item of response) {
                      // console.log('item.payload.doc.data()', item.payload.doc.data())
                      this.tableData.push(item.payload.doc.data());
                  }

                  //Initialize values
                  this.prev_strt_at = [];
                  this.pagination_clicked_count = 0;
                  this.disable_next = false;
                  this.disable_prev = false;

                  //Push first item to use for Previous action
                  this.push_prev_startAt(this.firstInResponse);
              }, error => {
              });
      }

  }

  //Show previous set
  prevPage() {
    this.disable_prev = true;
    this.afs.collection(`${dealers}/${this.authService.userData.id}/${calls}`, ref => ref
        .orderBy('timestamp', 'desc')
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        .limit(5)
    ).get()
        .subscribe(response => {
          this.firstInResponse = response.docs[0];
          this.lastInResponse = response.docs[response.docs.length - 1];

          this.tableData = [];
          for (let item of response.docs) {
            this.tableData.push(item.data());
          }

          //Maintaing page no.
          this.pagination_clicked_count--;

          //Pop not required value in array
          this.pop_prev_startAt(this.firstInResponse);

          //Enable buttons again
          this.disable_prev = false;
          this.disable_next = false;
        }, error => {
          this.disable_prev = false;
        });
  }

  nextPage() {
    this.disable_next = true;
    this.afs.collection(`${dealers}/${this.authService.userData.id}/${calls}`, ref => ref
        .limit(5)
        .orderBy('timestamp', 'desc')
        .startAfter(this.lastInResponse)
    ).get()
        .subscribe(response => {

          if (!response.docs.length) {
            this.disable_next = true;
            return;
          }

          this.firstInResponse = response.docs[0];

          this.lastInResponse = response.docs[response.docs.length - 1];
          this.tableData = [];
          for (let item of response.docs) {
            this.tableData.push(item.data());
          }

          this.pagination_clicked_count++;

          this.push_prev_startAt(this.firstInResponse);

          this.disable_next = false;
        }, error => {
          this.disable_next = false;
        });
  }

  //Add document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }

  //Remove not required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.data().id == element.data().id) {
        element = null;
      }
    });
  }

  //Return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1))
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  //Date formate
  readableDate(time) {
    var d = new Date(time);
    return d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
  }

  /**
   * initiateUserCall
   *         const callData = {
            // channelName: this.state.userData.friendshipId,
            roomName: roomName,
            channelName: roomName,
            senderName: loggedInUserData.username,
            receiverName: userData.username,
            // senderId: this.props.userId,
            senderId: loggedInUserData.id,
            receiverId: userData.id,
            type: type,
            // image: userData.image ? userData.image : null,
            receiverImage: userData.image ? userData.image : null,
            isDealerCall: false,
            senderImage: loggedInUserData.image
            // receiverData: JSON.stringify(userData),
            // senderData: JSON.stringify(loggedInUserData)
        } initiateNewCallHttp
   */
  initiateUserCall = (oldCallData, callType = 'audio') => {
    // console.log('oldCallData', oldCallData);

    const callData = {
      // ...oldCallData,
      roomName: oldCallData.roomName,
      channelName: oldCallData.roomName,
      timestamp: this.firebaseService.getFirestoreServerTimestamp(),
      type: callType,
      senderId: this.authService.userData.id,
      receiverId: oldCallData.receiverId === this.authService.userData.id ? oldCallData.senderId : oldCallData.receiverId,
      senderName: this.authService.userData.username,
      // senderName: oldCallData.receiverId === this.authService.userData.id ? oldCallData.senderName :oldCallData.receiverName,
      receiverName: oldCallData.receiverId === this.authService.userData.id ? oldCallData.senderName : oldCallData.receiverName,
      receiverImage: oldCallData.receiverId === this.authService.userData.id ? oldCallData.senderImage : oldCallData.receiverImage,
      isDealerCall: true,
      senderImage: this.authService.userData.image,
      initiatedFromDealerToUser: true
    };

    // console.log('callData', callData);
    // try {
    //   this.firebaseFunctionsService.callHttpFunction('initiateNewCallHttp', callData).then((data) => {
    //     console.log('data', data)

    //   })

    // } catch (error) {
    //   console.log('error', error)
    // }
    this.agoraCallService.outGoingCallData = callData;
    this.router.navigate(['/call'], { queryParams: { type: 'outgoing' } });
  }


}
