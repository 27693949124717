import { ProfilePage } from './../../../pages/menu/profile/profile.page';
import { ProfilePageModule } from './../../../pages/menu/profile/profile.module';
import { Component, OnInit } from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";
import {ApoinmentAlertComponent} from "../../apoinment-alert/apoinment-alert.component";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {UserConnectionService} from "../../../services/user.Connection.Service";
import * as firebase from 'firebase/app';
import { ProfcompComponent } from 'src/app/pages/menu/profcomp/profcomp.component';
import { StatusService } from 'src/app/services/status.service';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  userLimit=-1;
  status;
  userCount=-1;
  gotData;
  p='/calls';
  public display_menu = false;
  logUser = firebase.auth().currentUser;

  constructor(private modalCtrl: ModalController,
              private authService: AuthService,
              public router: Router,
              private sS:StatusService,
              private navCtrl:NavController,
              private zone: NgZone,
              private userConnctSrv: UserConnectionService) { }

  ngOnInit() {
    // console.log(this.logUser.email);
    this.sS.getData(this.logUser.email).subscribe(data => {
      // console.log("Got the details:\n"+data);
      this.gotData=data;
    });

    setTimeout(()=>{
      this.status=this.gotData.status;
      this.userLimit=this.gotData.total_leads;
      // console.log("status:\n"+this.status);

    },2000)

  }

  async openApoinmentModal() {
    let profileModal = await this.modalCtrl.create({
      component: ApoinmentAlertComponent,
      cssClass: 'apponment-modal',
      componentProps: {
        // call_status: callStatus,
        // count: count
      }
    });
    return await profileModal.present();
  }

  toggleMenu() {
    this.display_menu = !this.display_menu;
  }

  async open_top_menu(){
    // console.log("OPEN MENU called successfully");
    const modal = await this.modalCtrl.create({
      component: ProfcompComponent,
      cssClass: "top-menu-modal",
      componentProps: {
        'status': this.status,
        'userLimit':this.userLimit,
      }
    });

    await modal.present();
  }

  check(){
    this.router.navigate['https://business.directoapp.com/login'];
  }

  async logout() {
    this.check();
    // this.userConnctSrv.logoutCurrentUser();
    // await this.authService.doLogout();
    //     // this.zone.run(() => {
    //     //   console.log('force update the screen');
    //     // });
    // this.router.navigate['https://business.directoapp.com/login'];

  }

  openCall(){
    // console.log("Open Call Page!");
    this.router.navigateByUrl('/calls');
  }

  openChats(){
    // console.log("Open Call Page!");
    this.router.navigateByUrl('/chats');
  }

  openAppointments(){
    // console.log("Open Appointments Page!");
    this.router.navigateByUrl('/appoinment');
  }
}
