import { Component, Input, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { ContactDirectoPage } from '../contact-directo/contact-directo.page';
import { PasswordChangePage } from '../password-change/password-change.page';
import { ProfilePage } from '../profile/profile.page';
import { UserProfilePage } from '../user-profile/user-profile.page';
import { StatusPage } from '../../menu/status/status.page';


@Component({
  selector: 'app-profcomp',
  templateUrl: './profcomp.component.html',
  styleUrls: ['./profcomp.component.scss'],
})
export class ProfcompComponent implements OnInit {
  @Input() status;
  @Input() userLimit;
  logUser = firebase.auth().currentUser;
  constructor(public auth: AngularFireAuth,private router: Router,private modalCtrl: ModalController,private alertController:AlertController) { }

  ngOnInit() {}

  async profileModal(){
    this.modalCtrl.dismiss();
    // console.log("OPEN MENU called successfully");
    const modal = await this.modalCtrl.create({
      component: UserProfilePage,
      cssClass: "profile-modal"
    });

    await modal.present();
  }

  async statusModal(){
    this.modalCtrl.dismiss();
    // console.log("OPEN MENU called successfully");
    const modal = await this.modalCtrl.create({
      component: StatusPage,
      cssClass: "status-modal",
      componentProps: {
        'status': this.status,
        'userLimit':this.userLimit,
      }
    });

    await modal.present();
  }

  async passwordModal(){
    this.modalCtrl.dismiss();
    // console.log("OPEN MENU called successfully");
    const modal = await this.modalCtrl.create({
      component: PasswordChangePage,
      cssClass: "password-modal"
    });

    await modal.present();
  }

  async contactModal(){
    this.modalCtrl.dismiss();
    // console.log("OPEN MENU called successfully");
    const modal = await this.modalCtrl.create({
      component: ContactDirectoPage,
      cssClass: "contact-modal"
    });

    await modal.present();
  }

  close(){
    this.modalCtrl.dismiss();
  }

  showAlert() {

    this.alertController.create({
      header: 'Logout',
      subHeader: 'Are you sure you would like to log out?',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Yes',
        handler: () => {
          // console.log('Logging out!');
          this.logout();
        }
      },
      {
        text: 'No',
        handler: () => {
          // console.log('Cancel!');
        }
      },]
    }).then(res => {
      res.present();
    });
  }

  async logout() {
    await this.auth.auth.signOut().then(() => {
      // console.log("Logged out!");
      // this.router.navigateByUrl('https://business.directoapp.com/login');
      // this.router.navigateByUrl('/login');
    }).catch(err => {
      // console.log(err);
    })
    // this.router.navigateByUrl("www.directoapp.com/subscription/");
    window.open('https://business.directoapp.com/login', '_self'); return false;
    // window.open('https://plus.google.com/+NicRaboy', '_system', 'location=yes'); return false;
  }
}
