import { Component, OnInit } from '@angular/core';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent } from 'ngx-agora';
import { AgoraCallService } from '../../services/agora-call.service';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-call-component',
  templateUrl: './call-component.component.html',
  styleUrls: ['./call-component.component.scss'],
})
export class CallComponent implements OnInit {
  private client: AgoraClient;
  private localStream: Stream;
  private uid: number;

  userData;
  localCallId = 'agora_local';
  remoteCalls: string[] = [];
  constructor(private agoraService: AgoraCallService, private ngxAgoraService: NgxAgoraService, private authService: AuthService, private commonService: CommonService, private translationService: TranslationService) {
    this.uid = Math.floor(Math.random() * 100);

  }

  ngOnInit() {
    this.userData = this.authService.userData;
    this.client = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264' });
    this.assignClientHandlers();

    // Added in this step to initialize the local A/V stream
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: true, video: true, screen: false });
    this.assignLocalStreamHandlers();
    // this.agoraService.createCallWatch(this.authService.userData.id, (data) => this.showIncomingCallAlert(data));
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      // console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      // console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        // console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        // console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        // console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      // console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      // console.log('accessDenied');
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }
  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join = (onSuccess: (uid: number | string) => void, onFailure: (error: Error) => void, roomName: string): void => {
    this.client.join(null, roomName, this.uid, onSuccess, onFailure);
  }

  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  /**
   * showIncomingCallAlert()
   * @param: acceptCallback: func
   * @param: rejectCallback: func
   * 
   */
  showIncomingCallAlert = (callData) => {
    // tslint:disable-next-line: max-line-length
    const acceptCallback = (data) => {
      // console.warn('new call data', data);
      this.agoraService.isCallActive = true;
      this.initLocalStream(() => this.join((uid) => this.publish(), error => console.error(error), data.roomName));
    };

    const rejectCallback = (data) => {
      this.agoraService.isCallActive = false;
      // console.log('call rejected', data);
    };

    const buttons = [
      {
        text: this.translationService.getTranslation('ACCEPT'),
        cssClass: 'primary',
        handler: (blah) => {
          // console.log('Confirm Cancel: blah');

          acceptCallback(callData);
        }
      },
      {
        text: this.translationService.getTranslation('REJECT'),
        cssClass: 'secondary',
        handler: (blah) => {
          // console.log('Confirm Cancel: blah');
          rejectCallback(callData);
        }
      }
    ];
    // tslint:disable-next-line: max-line-length
    this.commonService.showAlert(this.translationService.getTranslation('INCOMING_CALL'), this.translationService.getTranslation('INCOMING_CALL_FROM', { name: 'test caller' }), buttons);
  }

}
