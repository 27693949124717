import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { firebaseConstants } from "../../../constants";
import { AuthService } from "../../../services/auth.service";
import { FirebaseService } from "../../../services/firebase.service";
import { FirebaseFunctionsService } from 'src/app/services/firebase.functions.service';
const { appointments,dealers, chatMessages, users, chats } = firebaseConstants.firestoreCollections;
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-appoinment-details',
  templateUrl: './appoinment-details.page.html',
  styleUrls: ['./appoinment-details.page.scss'],
})
export class AppoinmentDetailsPage implements OnInit {

  @Input() firstName: string;
  @Input() time;
  @Input() docId;
  @Input() otherUserId;
  otherUserData;
  thisUserData;
  
  logUser = firebase.auth().currentUser;
  border=1.5;
  border2=0;
  date=new Date();
  number;
  chosenTime;
  type='Video Call Appointment';
  d = new Date();
  updateView=false;
  constructor(
    private modalController:ModalController,
    private firebaseFunctionsService: FirebaseFunctionsService,
    private afs: AngularFirestore,
    private alertController:AlertController,
    private authService: AuthService,
    private datePipe:DatePipe,
  ) { }

  ngOnInit() {
    // console.log("InTo Doc Id:\n"+this.docId);
    this.afs.collection(`${dealers}/${this.logUser.uid}/${appointments}`).doc(`${this.docId}`).update({
      seen:true,
    }).then( ()=>{
      // console.log('!!!!!!! SEEN !!!!!!!!');
    }).catch(e=>{
      // console.log('Error in updating entry');
      });

      this.afs.collection(`${dealers}/${this.logUser.uid}/${appointments}/`).doc(`${this.docId}`).ref.get().then((res)=>{
        if(res.exists){
          this.chosenTime=res.data().dateTime;
      }
      else{
        // console.log('Nothing!');
      }
        });

        // console.log('OtherUserId: '+this.otherUserId);
      this.afs.collection(`${users}/`).doc(`${this.otherUserId}`).ref.get().then((res)=>{
        if(res.exists){
          this.otherUserData=res.data();
      }
      else{
        // console.log('Nothing!');
      }
        });

        this.afs.collection(`${dealers}/`).doc(`${this.logUser.uid}`).ref.get().then((res)=>{
          if(res.exists){
            this.thisUserData=res.data();
        }
        else{
          // console.log('Nothing!');
        }
          });
  }

  update(){
    this.updateView=true;
    
  }

  accepted(){
    this.afs.collection(`${dealers}/${this.logUser.uid}/${appointments}`).doc(`${this.docId}`).update({
      status:true,
      rescheduled:false,
    }).then( ()=>{
      // console.log('!!!!!!! SEEN !!!!!!!!');
    }).catch(e=>{
      // console.log('Error in updating entry');
      });
      // console.log("\nCHosen Time:\n"+this.chosenTime);
      // var dateTime = new Date(this.chosenTime);
      var dateTime=this.datePipe.transform(this.chosenTime.toDate(),"medium");
      const data={
        message:"Appointment accepted by "+this.logUser.displayName+"\n It is scheduled on:\n"+dateTime,
        date:this.chosenTime,
        // senderName: this.thisUserData.username,
        // senderData: JSON.stringify({
        //   ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
        // }),
        // receiverData: JSON.stringify({
        //   ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
        // }),
        senderId: this.logUser.uid,
        receiverId: this.otherUserId,
        receiverTokens: this.otherUserData.fcmTokens,
      }

      // console.log("Appointment Answering: Accepted!");
      this.firebaseFunctionsService.callHttpFunction('sendGeneralNotifications', data).then((data:any) => {
      });
      // console.log("Appointment Answered: Accepted!");

      this.showAcceptedAlert();
  }

  declined(){
    // console.log("\nCHosen Time:\n"+this.chosenTime);

    this.afs.collection(`${dealers}/${this.logUser.uid}/${appointments}`).doc(`${this.docId}`).update({
      status:false,
      rescheduled:false,
    }).then( ()=>{
      // console.log('!!!!!!! SEEN !!!!!!!!');
    }).catch(e=>{
      // console.log('Error in updating entry');
      });

      // let newDate;
      // newDate='cannot';
      // console.log("these are fcm tokens:"+this.otherUserData.fcmTokens);
      var dateTime=this.datePipe.transform(this.chosenTime.toDate(),"medium");
      const data={
        message:"Appointment declined by "+this.logUser.displayName+"\n which was requested for:\n"+dateTime, 
        // message:newDate,
        date:this.time,
        senderName: this.thisUserData.username,
        // senderData: JSON.stringify({
        //   ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
        // }),
        // receiverData: JSON.stringify({
        //   ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
        // }),
        senderId: this.logUser.uid,
        receiverId: this.otherUserId,
        receiverTokens: this.otherUserData.fcmTokens,
      }

      // console.log("Appointment Answering: Declined!");
      this.firebaseFunctionsService.callHttpFunction('sendGeneralNotifications', data).then((data:any) => {
      });
      // console.log("Appointment Answered: Declined!");


      this.showDeclinedAlert();
  }

  updated(){
    var dateTime = new Date(this.date);
    const data={
      date:this.date,
      message:"Appointment updated by "+this.logUser.displayName+"\n It is requested at:\n "+dateTime,
      senderName: this.thisUserData.username,
      // senderData: JSON.stringify({
      //   ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
      // }),
      // receiverData: JSON.stringify({
      //   ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
      // }),
      senderId: this.logUser.uid,
      receiverId: this.otherUserId,
      receiverTokens: this.otherUserData.fcmTokens,
    }

    // console.log("Appointment Answering: Updated!");
    this.firebaseFunctionsService.callHttpFunction('sendGeneralNotifications', data).then((data:any) => {
    });
    // console.log("Appointment Answered: Updated!");

    this.showAlert();
    // console.log('inputDate'+this.date);
    var dateTime = new Date(this.date);
    this.afs.collection(`${dealers}/${this.authService.userData.id}/${appointments}`).doc(`${this.docId}`).update({
      rescheduled:true,
      completed:false,
      dateTime:dateTime,
      status:false,
      type:'videoCallAppointment',
    }).catch(e=>{
      // console.log('Error in updating entry');
      });
  }


  showAlert() {
    this.dismiss();

    var audio = new Audio('../assets/aud.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 3000);

    this.alertController.create({
      header: ' ',
      subHeader: 'Appointment Updated!',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
        }
      },]
    }).then(res => {
      res.present();
    });
  }

  showAcceptedAlert() {
    this.dismiss();

    var audio = new Audio('../assets/aud.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 3000);

    this.alertController.create({
      header: ' ',
      subHeader: 'Appointment Approved!',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
        }
      },]
    }).then(res => {
      res.present();
    });

    
  }

  showDeclinedAlert() {

    this.dismiss();

    var audio = new Audio('../assets/aud.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 3000);

    this.alertController.create({
      header: ' ',
      subHeader: 'Appointment Declined!',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
        }
      },]
    }).then(res => {
      res.present();
    });
  }

  dismiss(){
    this.modalController.dismiss();
    // console.log(this.logUser);
  }

}

//       senderName: this.thisUserData.username,
//       senderData: JSON.stringify({
//         ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
//       }),
//       receiverData: JSON.stringify({
//         ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
//       }),
//       message:recentChatdata2.message,
//       senderId: this.thisUserData.id,
//       receiverId: this.otherUserData.id,
//       receiverTokens: this.otherUserData.fcmTokens,
//       isDealerChat: true,