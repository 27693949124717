export * from './firebase'
export const LANGUAGES_LIST = [{ code: 'en', label: 'English' }, { code: 'sp', label: 'Spanish' }];
export const GENDER_LIST = [{ value: 'male', label: 'Male' }, { value: 'female', label: 'Female' }];
export const DEFAULT_LANGUAGE_CODE = 'en';
export const LIST_DEFAULT_LIMIT = 10;
export const FIREBASE_ERROR_CODES = {
    'auth/email-already-in-use': 'EMAIL_ALREADY_IN_USE',
    'auth/invalid-email': 'EMAIL_INVALID',
    'auth/operation-not-allowed': 'OPERATION_NOT_ALLOWED',
    'auth/weak-password': 'WEAK_PASSWORD',
    'auth/user-disabled': 'USER_DISABLED',
    'auth/user-not-found': 'USER_NOT_FOUND',
    'auth/wrong-password': 'AUTH_WRONG_PASSWORD',
    'auth/missing-android-pkg': 'ANDROID_PACKAGE_NAME_REQUIRED',
    'auth/missing-continue-uri': 'CONTINUE_URL_REUIRED',
    'auth/missing-ios-bundle-id': 'IOS_BUNDLE_ID_REQUIRED',
    'auth/invalid-continue-uri': 'INVALID_CONTINUE_URL',
    'auth/unauthorized-continue-uri': 'CONTINUE_URL_DOMAIN_NOT_WHITE_LISTED',
}

export const AGORA_APP_ID = '73e3d91d7fe3446da4aa0670153dadb5'; //client
export const CALL_OUT_TIMEOUT = 45; //in seconds

export const PORTAL_API_URL = 'http://api.trackerinc.co:3636/app/';
