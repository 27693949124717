// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {

    vapidKey: '',
    apiKey: "AIzaSyBrDxJq6jfaNixOIPSy60IneCe1vv6MmUs",
    authDomain: "directo-app.firebaseapp.com",
    databaseURL: "https://directo-app.firebaseio.com",
    projectId: "directo-app",
    storageBucket: "directo-app.appspot.com",
    messagingSenderId: "822319675020",
    appId: "1:822319675020:web:61b5525f9b7a36db3b2da9",
    measurementId: "G-8N9N8Q8YC3"
  },
  agoraAppId: '73e3d91d7fe3446da4aa0670153dadb5'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
