import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';
import { firebaseConstants } from "../../../constants";
import { AuthService } from "../../../services/auth.service";
import { FirebaseService } from "../../../services/firebase.service";
import { FirebaseFunctionsService } from 'src/app/services/firebase.functions.service';
import { ThrowStmt } from '@angular/compiler';
const { dealers } = firebaseConstants.firestoreCollections;
const { users,appointments } = firebaseConstants.firestoreCollections;


@Component({
  selector: 'app-schedule-appointment',
  templateUrl: './schedule-appointment.page.html',
  styleUrls: ['./schedule-appointment.page.scss'],
})
export class ScheduleAppointmentPage implements OnInit {

  @Input() firstName: string;
  @Input() docId;
  logUser = firebase.auth().currentUser;
  border=1.5;
  border2=0;
  date=new Date();
  number;
  otherUserData;
  sendUserId;
  senderFCMTokens;
  type='Video Call Appointment';
  d = new Date();
  control=0;
  constructor(
    private modalController:ModalController,
    private afs: AngularFirestore,
    private alertController:AlertController,
    private firebaseFunctionsService: FirebaseFunctionsService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.afs.collection(`${dealers}/${this.logUser.uid}/${appointments}`).doc(`${this.docId}`).ref.get().then((res)=>{
      if(res.exists){
        // console.log('found USerId: '+res.data().userId);
        this.sendUserId=res.data().userId;
        this.senderFCMTokens=res.data().fcmTokens;
    }
    else{
      // console.log('Nothing!');
    }
      });

      setTimeout(()=>{
        this.control=1;
        this.find();
      },2000)

      // if(this.control==1){this.find();}
  }

  find(){

    // console.log('Sender User ID :'+this.sendUserId);
      let i=10000;
      for(;i>1;i--){}

      this.afs.collection(`${users}/`).doc(`${this.sendUserId}`).ref.get().then((res)=>{
        if(res.exists){
          this.otherUserData=res.data();
      }
      else{
        // console.log('Nothing!');
      }
        });
  }
  send(){
    
    const data={
      // date:this.date,
      message:"You have an appointment request from "+this.logUser.displayName,
      senderName: this.logUser.displayName,
      // senderData: JSON.stringify({
      //   ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
      // }),
      // receiverData: JSON.stringify({
      //   ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
      // }),
      senderId: this.logUser.uid,
      receiverId: this.sendUserId,
      receiverTokens: this.otherUserData.fcmTokens,

    }
    // console.log(this.senderFCMTokens+"\n"+data.message+"\n"+this.logUser.displayName+"\n"+this.logUser.uid+"\n"+this.sendUserId);

    // console.log("Appointment Answering: Updated!");
    this.firebaseFunctionsService.callHttpFunction('sendGeneralNotifications', data).then((data:any) => {
    });
    // console.log("Appointment Answered: Updated!");
  }

  update(){
    // console.log('inputDate'+this.date);
    var dateTime = new Date(this.date);
    this.afs.collection(`${dealers}/${this.authService.userData.id}/${appointments}`).add({
      completed:false,
      dateTime:dateTime,
      userName:this.firstName,
      dealerId:this.logUser.uid,
      userId:this.sendUserId,
      status:false,
      rescheduled:false,
      seen:true,
      // number:this.number,
      timestamp:new Date(),
      type:'videoCallAppointment',
    }).catch(e=>{
      // console.log('Error in updating entry');
      });


      const data={
        // date:this.date,
        message:"You have an appointment request from "+this.logUser.displayName+"\n At: "+dateTime,
        senderName: this.logUser.displayName,
        // senderData: JSON.stringify({
        //   ...this.thisUserData, friends:null, fcmTokens:null,timestamp:null, image:null
        // }),
        // receiverData: JSON.stringify({
        //   ...this.otherUserData, friends:null, fmTokens:null, timestamp:null, image:null
        // }),
        senderId: this.logUser.uid,
        receiverId: this.sendUserId,
        receiverTokens: this.otherUserData.fcmTokens,
  
      }
      // console.log(this.senderFCMTokens+"\n"+data.message+"\n"+this.logUser.displayName+"\n"+this.logUser.uid+"\n"+this.sendUserId);
  
      // console.log("Appointment Answering: Updated!");
      this.firebaseFunctionsService.callHttpFunction('sendGeneralNotifications', data).then((data:any) => {
      });
      // console.log("Appointment Answered: Updated!");



    this.showAlert();
  }


  showAlert() {
    this.dismiss();

    var audio = new Audio('../assets/aud.mp3');
    audio.play();
    setTimeout(() => audio.pause(), 3000);

    this.alertController.create({
      header: ' ',
      subHeader: 'Appointment Requested!',
      // message: 'Delete is permanent and cannot be undone!',
      buttons: [ {
        text: 'Okay',
        handler: () => {
          // console.log('Updated-->Okay!');
        }
      },]
    }).then(res => {
      res.present();
    });

  }

  dismiss(){
    this.modalController.dismiss();
    // console.log(this.logUser);
  }
}
